:local {
    .container {
        position: relative;
        height: 100vh;
    }

    .inner {
        max-width: 767px;
        width: 100%;
        line-height: 1.4;
        padding: 15px;

        display: flex;
        flex-direction: column;

        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .error404 {
        font-size: 120px;
        font-weight: 900;
        text-transform: uppercase;
        color: #666;
    }

    .title {
        font-size: 26px;
        font-weight: 700;
    }

    .description {
    }
}
